import PhoneAndroidOutlined from "@mui/icons-material/PhoneAndroidOutlined";
import { createSlice } from "@reduxjs/toolkit";

import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import InsertLinkOutlinedIcon from "@mui/icons-material/InsertLinkOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import BuildOutlinedIcon from "@mui/icons-material/BuildOutlined";

const initialState2 = {
  languages: [
    {
      code: "mm",
      label: "မြန်မာ",
      icon: "/assets/icons/ic_flag_mm.svg",
    },
    {
      code: "en",
      label: "English",
      icon: "/assets/icons/ic_flag_en.svg",
    },
  ],
  currentLanguage: {
    code: "en",
    label: "English",
    icon: "/assets/icons/ic_flag_en.svg",
  },
  en: {
    contactUs : "Contact Us",
    title: "MM Software 100​",
    nav: {
      home: "Home",
      services: "Services",
      projects: "Projects",
      contact: "Contact",
    },
    intro: {
      title: "Bringing Your Vision to Life",
      description:
        "Since 2019, we've been creating successful apps for our clients.You focus on your ideas, products, and business plan, and we'll handle the tech and development. Our expertise lies in Personal Apps, Real-time Chat, and Management Systems. ",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "Our Development Services",
      services: [
        {
          title: "App Creation",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "Web Based Solution",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "Tech Support",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "Maintence Care",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "Our Projects",
      projects: [
        {
          title: "QR Menu System",
          description:
            "The QR Menu system simplifies dining with instant access to menus through QR code scans. This touchless solution prioritizes safety and efficiency, providing a streamlined and modern dining experience.",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "Parcel Solution",
          description: "Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features.",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title : "Pandora App",
          description : "Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations.",
          image : "./assets/images/pandora.webp",
          url : "",
        },
        {
          title : "Customer Champion App",
          description : "The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app.",
          image : "./assets/images/customer-champion.avif",
          url : "",
        }
      ],
    },
    contacts: {
      title: "Contact Us",
      contacts: [
        {
          title: "Email",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "Phone",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "Viber",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "Messenger",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
  mm: {
    contactUs : "ဆက်သွယ်ရန်",
    title: "ဆော့ဝဲ (၁၀၀)​",
    nav: {
      home: "ပင်မစာမျက်နှာ",
      services: "ဝန်ဆောင်မှုများ",
      projects: "ပရောဂျက်များ",
      contact: "ဆက်သွယ်ရန်",
    },
    intro: {
      title: "စိတ်ကူးမှသည် ထုတ်ကုန်ဆီသို့",
      description: "နွေပူလာရင် အတော်လေး နေရခက်တာပါ။ နေပူချိန် အရမ်းပြင်းတဲ့အခါ နေ့ခင်းဘက်မှာ ပူသလို ညဘက်တွေမှာလည်း အရမ်းပူပါတယ်။ ဒီလို ပူပြင်းနေချိန်မှာ ကျန်းမာပြီး ခန္ဓာကိုယ်က အပူချိန်ကို ခံနိုင်စေဖို့ စားပေးသင့်တဲ့ အသီးအနှံလေးတွေက သပ်သပ်ကို ရှိတာပါ။ ဒီတော့ နွေပူပူမှာ စားပေးသင့်တဲ့ ဟင်းသီးဟင်းရွက်လေးတွေ က ဘာတွေများ ဖြစ်မလဲဆိုတာကို ဆောင်းပါးလေးထဲမှာ ဖတ်ကြည့်စေချင်ပါတယ်။",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "ကျွန်တော်တို့ရဲ့ ဝန်ဆောင်မှုများ",
      services: [
        {
          title: "မိုဘိုင်းအက်ပ် ရေးသားခြင်း",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "ဝဘ်ဆိုက် ရေးသားခြင်း",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "နည်းပညာ အကူအညီ",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "ပရောဂျက်",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "ကျွန်တော်တို့ရဲ့ ပရောဂျက်များ",
      projects: [
        {
          title: "ဒစ်ဂျစ်တယ် QR မီနူးစနစ်",
          description:
            "QR စကန်ဖတ်ပြီး ဟင်းပွဲ မီနူး ကြည့်ရတဲ့စနစ်",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "Parcel Solution",
          description: "Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features.",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title : "Pandora App",
          description : "Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations.",
          image : "./assets/images/pandora.webp",
          url : "",
        },
        {
          title : "Customer Champion App",
          description : "The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app.",
          image : "./assets/images/customer-champion.avif",
          url : "",
        }
      ],
    },
    contacts: {
      title: "ဆက်သွယ်ရန်",
      contacts: [
        {
          title: "အီးမေးလ််",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "ဖုန်း",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "ဗိုက်ဘာ",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "မက်ဆင်ဂျာ",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
};

const initialState = {
  languages: [
    {
      code: "mm",
      label: "မြန်မာ",
      icon: "/assets/icons/ic_flag_mm.svg",
    },
    {
      code: "en",
      label: "English",
      icon: "/assets/icons/ic_flag_en.svg",
    },
  ],
  currentLanguage: {
    code: "mm",
    label: "မြန်မာ",
    icon: "/assets/icons/ic_flag_mm.svg",
  },
  en: {
    contactUs : "Contact Us",
    title: "MM Software 100​",
    nav: {
      home: "Home",
      services: "Services",
      projects: "Projects",
      contact: "Contact",
    },
    intro: {
      title: "Bringing Your Vision to Life",
      description:
        "Since 2019, we've been creating successful apps for our clients.You focus on your ideas, products, and business plan, and we'll handle the tech and development. Our expertise lies in Personal Apps, Real-time Chat, and Management Systems. ",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "Our Development Services",
      services: [
        {
          title: "App Creation",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "Web Based Solution",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "Tech Support",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "Maintence Care",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "Our Projects",
      projects: [
        {
          title: "QR Menu System",
          description:
            "The QR Menu system simplifies dining with instant access to menus through QR code scans. This touchless solution prioritizes safety and efficiency, providing a streamlined and modern dining experience.",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "Parcel Solution",
          description: "Parcel Solution simplifies logistics, ensuring efficient and secure deliveries. Streamlining the shipping process, it offers businesses a reliable and effective tool for managing parcels seamlessly. Optimize your delivery operations with Parcel Solution's advanced features.",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title : "Pandora App",
          description : "Discover a vibrant online community with our app, reminiscent of Reddit. Engage in diverse discussions, share your thoughts, and explore a plethora of topics. Stay connected and informed in a dynamic and user-friendly platform that fosters meaningful conversations.",
          image : "./assets/images/pandora.webp",
          url : "",
        },
        {
          title : "Customer Champion App",
          description : "The Customer Champion app is your dedicated tool for delivering exceptional customer service. With streamlined communication features, it empowers businesses to engage with customers, address concerns, and build lasting relationships. Elevate your customer support experience with the Customer Champion app.",
          image : "./assets/images/customer-champion.avif",
          url : "",
        }
      ],
    },
    contacts: {
      title: "Contact Us",
      contacts: [
        {
          title: "Email",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "Phone",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "Viber",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "Messenger",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
  mm: {
    contactUs : "ဆက်သွယ်ရန်",
    title: "ဆော့ဝဲ (၁၀၀)​",
    nav: {
      home: "ပင်မစာမျက်နှာ",
      services: "ဝန်ဆောင်မှုများ",
      projects: "ပရောဂျက်များ",
      contact: "ဆက်သွယ်ရန်",
    },
    intro: {
      title: "စိတ်ကူးမှသည် ထုတ်ကုန်ဆီသို့",
      description: "၂၀၁၉ ခုနှစ်မှ အစပြု၍ ကောင်းမွန်သောအတွေ့အကြုံ၊ ကျွမ်းကျင်သောလုပ်ဆောင်ချက်များဖြင့် ရပ်တည်လျက်ရှိသော mmsoftware100 ဖြစ်ပါသည်။ မိတ်ဆွေတို့ရဲ့ လုပ်ငန်းများ ဖွံ့ဖြိုးတိုးတက်ရန်အတွက် ကောင်းမွန်သောစီမံခန့်ခွဲမှုစနစ်များဖြင့် သင့်စိတ်ကူးအား လက်တွေ့ဖြစ်လာစေရန်ကူညီဖန်တီးကူးပြောင်းပေးလျက်ရှိပါသည်။ mmsoftware100 ၏ စွမ်းဆောင်ကူညီဖြေရှင်းပေးနိုင်မှုများအား Personal Apps၊ Real-time Chat နှင့် Management Systems များဖန်တီးထုတ်လုပ်ထားရှိမှုများမှ ဖော်ပြလျက်ရှိပါသည်။",
      image: "./assets/images/intro-section.png",
      url: "",
    },
    services: {
      title: "ကျွန်တော်တို့ရဲ့ ဝန်ဆောင်မှုများ",
      services: [
        {
          title: "မိုဘိုင်းအက်ပ် ရေးသားခြင်း",
          icon: <PhoneAndroidOutlinedIcon />,
        },
        {
          title: "ဝဘ်ဆိုက် ရေးသားခြင်း",
          icon: <InsertLinkOutlinedIcon />,
        },
        {
          title: "နည်းပညာ အကူအညီ",
          icon: <DevicesOutlinedIcon />,
        },
        {
          title: "ပရောဂျက်",
          icon: <BuildOutlinedIcon />,
        },
      ],
    },
    projects: {
      title: "ကျွန်တော်တို့ရဲ့ ပရောဂျက်များ",
      projects: [
        {
          title: "ဒစ်ဂျစ်တယ် QR မီနူးစနစ်",
          description:
            "ခေတ်မီဆန်းသစ်သော  ဒစ်ဂျစ်တယ် QR မီနူးဖြင့်အစားအသောက်မှာယူမှုစနစ်တစ်ခုဖြစ်ရုံသာမက မိမိကိုယ်ပိုင် စမတ်ဖုန်းမှလွဲ၍ အခြားသော အရာများအား ထိတွေ့ကိုင်တွယ်ရန်မလိုခြင်းကြောင့် ကျန်းမာရေးအရများစွာ အထောက်အကူပေးသော ဒစ်ဂျစ်တယ် QR မီနူးစနစ်ဖြင့် သင့်လုပ်ငန်းများအား ကူညီပါရစေ..",
          image: "./assets/images/qr-menu.png",
          url: "",
        },
        {
          title: "ပါဆယ်ပေးပို့လက်ခံမှုစီမံခန့်ခွဲမှုစနစ်",
          description: "ပါဆယ်ပေးပို့လက်ခံမှုများအား လုံခြုံစိတ်ချရမှု အာမခံချက်အပြည့်ဖြင့် ရိုးရှင်းလွယ်ကူသော အသုံးပြုမှုနည်းလမ်းများအတိုင်း အသုံးပြု၍သင်၏ ပါဆယ်ပေးပို့လက်ခံမှု လုပ်ငန်းအား အောင်မြင်တိုးတက်ကြီးပွားစေရန် ပါဆယ်ပေးပို့လက်ခံမှု စီမံခန့်ခွဲမှုစနစ် မှ အပြည့်အဝ ကူညီလျက်ပါ။",
          image: "./assets/images/project-one.png",
          url: "",
        },
        {
          title : "ပန်ဒိုရာ လူမှုဆက်သွယ်ရေးစနစ်",
          description : "မတူကွဲပြားသော အတွေးအမြင် ဗဟုသုတများအား အဓိပ္ပါယ်ပြည့်ဝသော စကားဝိုင်းအတွင်း မိမိ၏ကိုယ်ရေးရာဇဝင်အားဖော်ပြရန်မလိုပဲ လွတ်လပ်စိတ်ချမ်းသာစွာပြောဆို ဆွေးနွေးနိုင်ရန်အတွက် ပန်ဒိုရာ လူမှုဆက်သွယ်ရေးစနစ်က ဖိတ်ခေါ်လျက်ပါ။",
          image : "./assets/images/pandora.webp",
          url : "",
        },
        {
          title : "တန်ဖိုးဖြတ်မရသော မိမိ၏ဖောက်သည်များအား ပံ့ပိုးကူညီမှုစနစ်",
          description : "မိတ်ဆွေတို့ရဲ့ ထုတ်ကုန်နှင့် ဝန်ဆောင်မှုများအား  အဆင်အမြဲအားပေးလျက်ရှိသော ဖောက်သည်များအား ဂရုတစိုက်ဖြင့် အကူအညီပေးနိုင်စေရန်၊ ကောင်းမွန်သော ရေရှည်ဆက်ဆံရေးများအား စိုးရိမ်ကြောင့်ကျမှုမရှိ ဆက်သွယ်ကူညီ ဖြေရှင်းဆောင်ရွက်နိုင်ရန်အတွက် Customer Champion app က သင့်အတွက်အမြဲအသင့်ပါ။",
          image : "./assets/images/customer-champion.avif",
          url : "",
        }
      ],
    },
    contacts: {
      title: "ဆက်သွယ်ရန်",
      contacts: [
        {
          title: "အီးမေးလ််",
          description: "admin@email.com",
          image: "email.png",
          url: "mailto:admin@mmsoftware100.com",
        },
        {
          title: "ဖုန်း",
          description: "09963170133",
          image: "phone.png",
          url: "tel:+959963170133",
        },
        {
          title: "ဗိုက်ဘာ",
          description: "09963170133",
          image: "viber.png",
          url: "viber://chat?number=+959963170133",
        },
        {
          title: "မက်ဆင်ဂျာ",
          description: "Mm Software 100",
          image: "messenger.png",
          url: "https://fb.me/mmsoftware100",
        },
      ],
    },
  },
};

export const landingSlice = createSlice({
  name: "landing",
  initialState,
  reducers: {
    setCurrentLanguage: (state, action) => {
      state.currentLanguage = action.payload;
    },
  },
});

export const { setCurrentLanguage } = landingSlice.actions;

export const selectTitle = (state) =>
  state.landing[state.landing.currentLanguage.code].title;
export const selectNav = (state) =>
  state.landing[state.landing.currentLanguage.code].nav;
export const selectLanguages = (state) => state.landing.languages;
export const selectCurrentLanguage = (state) => state.landing.currentLanguage;
export const selectIntro = (state) =>
  state.landing[state.landing.currentLanguage.code].intro;
export const selectServices = (state) =>
  state.landing[state.landing.currentLanguage.code].services;
export const selectProjects = (state) =>
  state.landing[state.landing.currentLanguage.code].projects;
export const selectContacts = (state) =>
  state.landing[state.landing.currentLanguage.code].contacts;

export const selectContactUs = (state) =>
state.landing[state.landing.currentLanguage.code].contactUs;

export default landingSlice.reducer;
