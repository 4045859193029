
import { Container } from "@mui/material";
import { Link as ScrollLink } from "react-scroll";
function ContactUsButton() {
  return (
    <>
    <ScrollLink to={'contact'} spy={true} smooth={true} duration={1000} sx={{cursor:'pointer'}}>
              <Container sx={{backgroundColor:'blue', padding:'24px',color:'white', borderRadius:'16px', marginTop:'24px', textAlign:'center', cursor:'pointer', fontWeight:'bold'}} >Contact Us</Container>
            </ScrollLink>
    </>
  );
}

export default ContactUsButton;
