import { Button, Container, Grid, Stack, Typography } from "@mui/material";

import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import { Link as ScrollLink } from "react-scroll";
import { useSelector } from "react-redux";
import { selectContactUs, selectIntro } from "features/landing/landingSlice";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

function IntroSection() {
  // global state
  const { title, description, image, url } = useSelector(selectIntro);
  const contactUs = useSelector(selectContactUs);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Stack
            direction={"column"}
            fullwidth
            backgroundColor={"transparent"}
            pt={10}
          >
            <Typography
              sx={{ fontSize: "24px", fontFamily: "MyanmarPhetsot" }}
              mb={2}
            >
              {title}
            </Typography>
             <span style={{ fontSize: "20px", lineHeight:"2"}}>{description}</span>
            {/* <Typography sx={{ fontSize: "20px", fontFamily: "MyanmarPhetsot" }}>
              {description}
            </Typography> */}
            <ScrollLink
              to={"contact"}
              spy={true}
              smooth={true}
              duration={1000}
              sx={{ cursor: "pointer" }}
            >
              <Container
                sx={{
                  backgroundColor: "blue",
                  padding: "24px",
                  color: "white",
                  borderRadius: "16px",
                  marginTop: "24px",
                  textAlign: "center",
                  cursor: "pointer",
                  fontWeight: "bold",
                }}
              >
                {contactUs}
              </Container>
            </ScrollLink>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6}>
          <img
            alt="intro section"
            // src="./assets/images/intro-section.png"
            src={image}
            width={"100%"}
          />
        </Grid>
      </Grid>
    </>
  );
}

export default IntroSection;
