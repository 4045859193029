import { Container, Link, Stack, Typography } from "@mui/material";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";

import useMediaQuery from "@mui/material/useMediaQuery";
import { Link as ScrollLink } from "react-scroll";
import { useSelector } from "react-redux";
import { selectNav, selectTitle } from "features/landing/landingSlice";
import LanguagePopover from "./language-popover";

function NavigationBar() {
  // required state

  const title = useSelector(selectTitle);
  const nav = useSelector(selectNav);
  const matches = useMediaQuery("(min-width:700px)");

  // return <span>{`(min-width:600px) matches: ${matches}`}</span>;

  const NavLink = ({ to, name }) => {
    return (
      <ScrollLink to={to} spy={true} smooth={true} duration={500}>
        <Container
          sx={{
            backgroundColor: "transparent",
            cursor: "pointer",
            padding: "16px",
            "&:hover": {
              color: "grey",
            },
          }}
        >
          {name}
        </Container>
      </ScrollLink>
    );
  };
  return (
    <>
      {/* <h2>Nav Bar</h2>
        <span>{`(min-width:600px) matches: ${matches}`}</span> */}
      <Stack
        direction={"row"}
        justifyContent={"space-between"}
        alignItems={"center"}
        sx={{ backgroundColor: "transparent" }}
      >
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ backgroundColor: "transparent" }}
        >
          <img
            src="./assets/images/brand-image2.png"
            alt="brand_image"
            height={"100"}
            style={{ borderRadius: "8px" }}
          />
          <Typography p={2} sx={{ fontSize: "24px", fontWeight: "bold", fontFamily: "MyanmarPhetsot" }}>
            {" "}
            {title}{" "}
          </Typography>
        </Stack>

        {matches && (
          <Stack direction={"row"} spacing={2} alignItems={"center"}>
            {/* loop key->value of  id->name nav */}
            {Object.entries(nav).map(([key, value]) => (
              <NavLink name={value} to={key} />
            ))}

            {/* language ပြောင်းတဲ့ pop over ထည့်ရန် */}
            <LanguagePopover />

            {/* <NavLink name="Home" to="home" />
            <NavLink name="Services" to="services" />
            <NavLink name="Projects" to="projects" />
            <NavLink name="Contact" to="contact" /> */}

            {/* <Link href="#home" sx={{ textDecoration:'none'}}><Container sx={{backgroundColor:'red'}}>Home</Container></Link>
                <Link href="#services" sx={{ textDecoration:'none'}}><Container sx={{backgroundColor:'red'}}>Services</Container></Link>
                <Link href="#projects" sx={{ textDecoration:'none'}}><Container sx={{backgroundColor:'red'}}>Projects</Container></Link>
                <Link href="#contact" sx={{ textDecoration:'none'}}><Container sx={{backgroundColor:'red'}}>Contact</Container></Link> */}
          </Stack>
        )}

        {!matches && (
          <LanguagePopover />
          // <MenuOpenIcon sx={{ fontSize: 36, backgroundColor: "red" }} />
        )}
      </Stack>
    </>
  );
}

export default NavigationBar;
